import React, { useEffect, useState } from 'react';
import Drawer from 'react-modern-drawer';
import { HiOutlineClock, HiOutlineX } from 'react-icons/hi';
import breakpoints from '@/utils/breakpoints';
import { extractSessionTime } from '@/utils/date';

const SessionDetailsDrawer = ({
  timezone,
  session,
  handleClose,
  isOpen,
}: {
  timezone: string;
  session: {
    description: string;
    subTitle?: string;
    from: string;
    to: string;
    title: string;
    videoId: string;
    speakers: {
      companyImage2: {
        asset: {
          url: string;
        };
      };
      companyName: string;
      fullName: string;
      id: string;
      link: string;
      role: string;
      image: {
        asset: {
          gatsbyImageData: any;
        };
      };
    }[];
  };
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const { title, subTitle, from, to, description, speakers } = session;
  const [size, setSize] = useState('40vw');

  useEffect(() => {
    const getSize = () => {
      if (window.innerWidth >= breakpoints.lg) {
        return '40vw';
      }
      return '80vw';
    };
    setSize(getSize());
  }, []);

  const { formattedTime: startTime } = extractSessionTime({
    eventDateTime: from,
  });
  const { formattedTime: endTime } = extractSessionTime({
    eventDateTime: to,
  });

  return (
    <Drawer
      lockBackgroundScroll
      size={size}
      style={{
        background: '#4A2FF9',
        borderLeft: '1px solid #FFFFFF33',
      }}
      className="mb-8 overflow-auto px-6 pt-[92px] md:px-[73px] md:py-[80px]"
      direction="right"
      open={isOpen}
      onClose={handleClose}
    >
      <div className="text-[#DBD6D0]">
        <HiOutlineX
          type="button"
          onClick={handleClose}
          size={24}
          className="absolute right-6 top-6 cursor-pointer text-white transition-colors hover:text-[#DBD6D0] md:right-8 md:top-8"
        />
        <h3 className="text-left text-[32px] font-medium leading-[35.2px] md:text-[32px] md:leading-[34.03px]">
          {title}
        </h3>
        {subTitle && (
          <p className="mb-8 mt-6 text-2xl font-normal leading-[26.4px] tracking-[0.5px] md:mb-4 md:mt-9 md:text-xl md:leading-[38.89px] md:tracking-[0.5px]">
            {subTitle}
          </p>
        )}
        <p className="mb-8 text-base font-normal leading-6 tracking-[0.5px] md:mb-16 md:leading-[20.8px]">
          {description}
        </p>

        <div>
          <div className="mb-9 text-xl font-normal leading-[38.89px] tracking-[0.5px] md:mb-6 md:text-xl md:leading-[38.89px]">
            Speakers:
          </div>
          <ul>
            {speakers.map((i) => {
              const companyImage2Src = i.companyImage2?.asset?.url;
              const profileSrc =
                i.image?.asset?.gatsbyImageData?.images?.fallback?.src;
              return (
                <li key={i.id} className="mb-8 flex items-start gap-4">
                  <img
                    className="h-20 w-20 rounded-full"
                    src={profileSrc}
                    alt={i.fullName}
                  />

                  <div className="flex flex-col">
                    <p className="font-bold text-white md:text-2xl md:leading-[29.05px]">
                      {i.fullName}
                    </p>
                    <p className="text-xs font-normal leading-6">{i.role}</p>
                    <img
                      className="h-5 w-min"
                      src={companyImage2Src}
                      alt={i.companyName}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="flex items-center gap-6 text-xl font-normal leading-[28.5px]">
            <HiOutlineClock size={20} color="white" />
            <time className="flex gap-4 font-overpass-mono text-xl font-normal leading-[28.5px] text-white md:text-xl md:leading-[28.5px]">
              {startTime} - {endTime} {timezone}
            </time>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default SessionDetailsDrawer;
