import GalileoLogo from '@/assets/genai-productinize-2024/galileo-logo';
import { cn } from '@/utils/tailwindUtils';
import React from 'react';
import Mesh from './mesh.svg';

const AccessCard = ({ className, hero }: { hero: any; className: string }) => {
  return (
    <div
      className={cn(
        'w-full max-w-52 overflow-hidden rounded-3xl bg-[#383838] pb-4 pt-4 md:max-w-[420px] md:pb-7 md:pt-7',
        className,
      )}
    >
      <div className="mx-auto h-1.5 w-[81px] rounded-md bg-white" />
      <p className="mt-6 pl-4 text-xs font-light leading-[12.6px] text-white md:mt-12 md:pl-8 md:text-base md:leading-[16.8px]">
        Access Card
      </p>
      <div className="relative mt-4 pb-24 pr-8 md:mt-9 md:pb-[279px] md:pr-5">
        <img
          src={Mesh}
          alt="mesh"
          className="absolute top-0 h-24 w-[120px] md:h-[253px] md:w-[327px]"
        />
        <div className="ml-auto h-5 w-5 bg-white md:h-9 md:w-9" />
      </div>

      <div className="px-4 md:px-7">
        <p className="mb-4 max-w-32 text-[13px] font-bold leading-[13.65px] text-white md:mb-11 md:max-w-56 md:text-2xl md:leading-[25.2px]">
          {hero.title} {hero.version}
        </p>
        <div className="flex items-center justify-between gap-4">
          <GalileoLogo className="w-[51px] md:w-24" />
          <p className="text-[13.12px] font-bold leading-[13.78px] text-[#FFFFFF66] md:text-2xl md:leading-[25.2px]">
            2024
          </p>
        </div>
      </div>
    </div>
  );
};

export default AccessCard;
