import React, { useEffect, useState } from 'react';
import {
  HiOutlineCalendar,
  HiOutlineClock,
  HiOutlineTag,
  HiOutlineLocationMarker,
} from 'react-icons/hi';
import Container from './container';
import NewsletterForm from '@/components/common/v4/newsletter-form';
import { cn } from '@/utils/tailwindUtils';
import breakpoints from '@/utils/breakpoints';
import { extractDateAndTime, extractSessionTime } from '@/utils/date';

const RegisterForm = ({
  formSection,
  hero,
}: {
  formSection: {
    hubspotFormId: string;
    hubspotPortalId: string;
    subTitle: string;
    title: string;
    redirectLink: {
      label: string;
      slug: {
        current: string;
      };
    };
  };
  hero: {
    title: string;
    version: string;
    eventDateTime: string;
    eventDateTimeEnd: string;
    eventType: string;
    cost: string;
    timezone: string;
  };
}) => {
  const { eventDateTime, eventDateTimeEnd, eventType, cost, timezone } = hero;
  const { formattedDate } = extractDateAndTime({
    eventDateTime,
    timezone,
  });

  const { formattedTime: startTime } = extractSessionTime({ eventDateTime });
  const { formattedTime: endTime } = extractSessionTime({
    eventDateTime: eventDateTimeEnd,
  });

  const formattedTime = `${startTime} - ${endTime} ${timezone}`;

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth < breakpoints.sm);
  }, []);

  const renderRegisterForm = (classes?: string) => {
    return (
      <>
        <div className="relative -left-5 mb-5 block h-[1px] w-[100vw] bg-[#FFFFFF33] md:hidden" />
        <div id="register" className="flex flex-1 pt-24 md:pt-0">
          <div
            className={cn(
              'flex-1 rounded-r-xl p-1 md:mt-28 md:bg-[#252525]',
              classes,
            )}
          >
            <div className="bg-[url(/dots.png)] bg-cover bg-no-repeat pb-14 md:px-[75px] md:pb-[75px] md:pt-[84px]">
              <h4 className="mb-8 text-left font-dm-sans text-2xl font-bold leading-[31.25px] text-white md:text-[32px] md:leading-[38.4px]">
                Register Now
              </h4>
              <NewsletterForm
                portalId={formSection.hubspotPortalId}
                formId={formSection.hubspotFormId}
                cssClass="genai-productionize-2024-register-form "
                containerClass="md:pt-32"
                redirectUrl={undefined}
                tyMessageClassname="text-lg font-normal leading-[27px] text-[#FFFFFFCC]"
                tyMessage="Thank you for registering! You will receive a confirmation email soon."
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="bg-[#1E1E1E] md:bg-[#1A1A1A]">
      <Container className="flex flex-col pb-9 pt-16 md:flex-row md:pb-0 md:pt-28">
        <div className="flex-1 rounded-l-xl md:mt-28 md:border-r md:border-solid md:border-r-[#FFFFFF33] md:bg-[#252525] md:px-[75px] md:pb-[75px] md:pt-[84px]">
          <h3 className="pb-8 font-dm-sans text-[32px] font-bold leading-[35.2px] text-white md:pb-4 md:text-[52px] md:leading-[57.2px]">
            {hero.title} {hero.version}
          </h3>
          <p className="mb-[52px] text-base font-normal leading-[25.6px] text-[#FFFFFFCC] md:mb-52 md:text-lg md:leading-[27px]">
            {formSection.subTitle}
          </p>

          {isMobile && renderRegisterForm()}

          <div className="border-t border-solid border-t-[#FFFFFF33] pt-6">
            <ul className="grid grid-cols-2 gap-x-12 pb-8">
              {[
                {
                  icon: <HiOutlineLocationMarker size={20} color="white" />,
                  text: eventType,
                },
                {
                  icon: <HiOutlineCalendar size={20} color="white" />,
                  text: formattedDate,
                },
                {
                  icon: <HiOutlineTag size={20} color="white" />,
                  text: cost,
                },
                {
                  icon: <HiOutlineClock size={20} color="white" />,
                  text: formattedTime,
                },
              ].map((i, index) => (
                <li
                  key={index}
                  className="flex items-center justify-between border-solid border-[#FFFFFF33] py-5 first:border-b last:border-t"
                >
                  <span>{i.icon}</span>{' '}
                  <span className="text-right font-overpass-mono text-xl font-normal leading-[28.5px] text-white">
                    {i.text}
                  </span>
                </li>
              ))}
            </ul>

            <div className="h-[104px] w-full bg-[url(/dots-pattern.svg)] bg-cover bg-no-repeat" />
          </div>
        </div>
        {!isMobile && renderRegisterForm()}
      </Container>
    </div>
  );
};

export default RegisterForm;
