import React from 'react';
import { graphql } from 'gatsby';
import Layout from '@/components/common/layout';
import {
  Hero,
  About,
  Speakers,
  Sessions,
  PreviousSessions,
} from '@/components/genai-productionize-v2';
import SEO from '@/components/seo';
import RegisterForm from '@/components/genai-productionize-v2/register-form';

const GenAi = ({ data }) => {
  const { hero, about, formSection, sessions, seo } = data.sanityGenaiV2;

  return (
    <Layout>
      <Hero hero={hero} />
      <Speakers sessions={sessions} />
      <About about={about} hero={hero} />
      <Sessions sessions={sessions} timezone={hero.timezone} />
      <RegisterForm formSection={formSection} hero={hero} />
      <PreviousSessions sessions={sessions} />
    </Layout>
  );
};

export const query = graphql`
  query GenAiQuery {
    sanitySiteSettings {
      logoWhite {
        asset {
          gatsbyImageData
        }
      }
    }
    sanityGenaiV2 {
      seo {
        metaTitle
        metaDescription
        image {
          asset {
            gatsbyImageData(width: 1280)
            url
            localFile(width: 1280) {
              publicURL
              childImageSharp {
                fixed(width: 1280) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
      }
      hero {
        title
        version
        ctaLink {
          label
          slug {
            current
          }
        }
        subTitle
        eventType
        cost
        eventDateTime
        eventDateTimeEnd
        timezone
        logos {
          asset {
            url
          }
        }
      }
      about {
        title
        subTitle
      }
      formSection {
        hubspotFormId
        hubspotPortalId
        subTitle
        title
        redirectLink {
          label
          slug {
            current
          }
        }
      }
      sessions {
        oldTitle
        title
        oldSessions {
          description
          subTitle
          title
          videoId
          from
          to
          speakers {
            order
            companyImage {
              asset {
                url
              }
            }
            companyName
            fullName
            id
            link
            role
            image {
              asset {
                gatsbyImageData
              }
            }
          }
        }
        sessions {
          description
          subTitle
          title
          videoId
          from
          to
          speakers {
            order
            companyImage {
              asset {
                url
              }
            }
            companyImage2 {
              asset {
                url
              }
            }
            companyName
            fullName
            id
            link
            role
            image {
              asset {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;

export default GenAi;

export const Head = () => {
  return (
    <SEO
      title={'GenAI Productionize 2.0'}
      description={'The premier conference for GenAI application development'}
      image={'/genai-productionize-2.0-og.png'}
      type="article"
      imageWidth={1200}
      imageHeight={670}
    />
  );
};
