import React from 'react';
import { HiArrowRight } from 'react-icons/hi';
import Carousel from './carousel';
import Container from '../container';

type Session = {
  description: string;
  subTitle: string;
  time: string;
  title: string;
  videoId: string;
  speakers: {
    companyName: string;
    fullName: string;
    id: string;
    link: string;
    role: string;
    image: {
      asset: {
        gatsbyImageData: any;
      };
    };
  }[];
};

const PreviousSessions = ({
  sessions,
}: {
  sessions: {
    title: string;
    oldTitle: string;
    newSessions: Session[];
    oldSessions: Session[];
  };
}) => {
  return (
    <div className="bg-[#1A1A1A] pt-40">
      <Container>
        <h3 className="mb-6 font-dm-sans text-[52px] font-bold leading-[57.2px] text-white">
          {sessions.oldTitle}
        </h3>
      </Container>
      <div className="mx-5 grid grid-cols-[1fr_min(1440px,100%)_1fr] gap-x-0 lg:ml-5 lg:mr-0">
        <Carousel
          sessions={sessions.oldSessions}
          className="col-[2_/_3] w-full max-w-none lg:col-[2_/_-1]"
        />
      </div>
    </div>
  );
};

export default PreviousSessions;
