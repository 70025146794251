const defaultTheme = require('tailwindcss/defaultTheme');

/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    './src/pages/**/*.{tsx,svg,jsx,js}',
    './src/components/**/*.{tsx,svg,jsx,js}',
    './src/templates/**/*.{tsx,svg,jsx,js}',
  ],
  theme: {
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
    },
    extend: {
      zIndex: {
        60: '60',
        70: '67',
        80: '68',
        90: '69',
        100: '100',
      },
      spacing: {
        'hero-gradient-spacing': 'calc(min(50%, 720px) + min(40%, 576px))',
      },
      aspectRatio: {
        '4/3': '4 / 3',
        '3/4': '3 / 4',
        '4/3': '4 / 3',
      },
      backgroundImage: {
        'btn-bar': `linear-gradient(270deg, #FFD286 -3.17%, #EF43CD 56%, #695EFF 108.52%)`,
        page: `radial-gradient(132.66% 100% at 19.51% 0%, rgba(255, 175, 39, 0.04) 0%, rgba(222, 0, 178, 0.04) 56.41%, rgba(47, 33, 250, 0.04) 100%)`,
        twelve: `radial-gradient(132.66% 100% at 19.51% 0%, rgba(255, 175, 39, 0.12) 0%, rgba(222, 0, 178, 0.12) 56.41%, rgba(47, 33, 250, 0.12) 100%)`,
        feature: `linear-gradient(180deg, #D5E2F1 -29.58%, #EEC6FB 37.24%, #FFFFFF 100%)`,
        bar: `linear-gradient(124.68deg, #F9CE85 -42.61%, #F768DB 64.85%)`,
        integrations: `linear-gradient(90deg, #FBECF8 10.54%, #FAE9F8 44.88%, #F5E0F7 68.18%, #EED1F7 88.14%, #E7C4F6 100%)`,
        hero: `linear-gradient(270deg, #FEF6FD 17.07%, rgba(255, 246, 252, 0) 100%)`,
        'gradient-hero':
          'linear-gradient(103.6deg,#ffaf27 -7.57%,#de00b2 56.51%,#2f21fa 106.02%)',
        'integrate-stack':
          'linear-gradient(47.63deg, #FFD286 -6.54%, #EF43CD 43.93%, #695EFF 102.74%)',
        'build-for': 'url("/img/build-enterprise-bg.svg")',
        research: 'url("/img/research-bg.svg")',
        'card-pipe':
          'linear-gradient(rgba(90,90,90,1) 0%, rgba(192,192,192,1) 100%)',
        'card-pipe-reverse':
          'linear-gradient(rgba(192,192,192,1) 0%, rgba(90,90,90,1) 100%)',
        'gradient-text': 'linear-gradient(to right,#EF43CD 0%, #695EFF 100%)',
      },
      backgroundPosition: {
        'buildFor-position': 'calc(50% - min(504px, 70%)) calc(100% + 120px)',
      },
      boxShadow: {
        feature: '0px 30px 61px -30px rgba(255, 151, 227, 0.35)',
        tool: '0px 28.4848px 57.9192px -28.4848px rgba(255, 151, 227, 0.35)',
        cta: '0px 30px 61px -30px rgba(255, 151, 227, 0.35)',
        hero: '0px 4px 16px rgba(255, 151, 227, 0.4)',
        form: '0px 30px 61px -30px rgba(255, 204, 241, 0.35)',
      },
      colors: {
        'orangey-yellow': '#FFAF27',
        'reddish-magenta': '#DE00B2',
        'purply-blue': '#2F21FA',
        'smoky-black': '#070707',
        'milky-white': '#FEFEFE',
        'pearl-black': '#111827',
        pearl: '#fdf8f9',
        y: '#FFD286',
        rm: '#EF43CD',
        pb: '#695EFF',
        bluey: '#EDF0FF',
        grayy: '#8F8F8F',
        purpley: '#8370FF',

        body: '#FBF7F7',
        green: {
          DEFAULT: '#05FF00',
          500: '#45CB6A',
        },
        yellow: '#FFE600',
        dark: {
          80: '#281742',
          600: '#434343',
          800: '#1B1B1B',
          900: '#281F29',
          1000: '#151515',
          1100: '#202020',
        },
        red: {
          DEFAULT: '#FF1212',
          100: '#FF98AA',
          500: '#FF3A5E',
        },

        blue: {
          400: '#68A4FF',
        },
        pink: {
          DEFAULT: '#F963BC',
        },
        violet: {
          400: '#F6F7FF',
        },
        grey: {
          50: '#030303',
          100: '#F4F3FE',
          200: '#E9E8ED',
          250: '#DADADA',
          300: '#D8D8D8',
          400: '#949494',
          500: '#939393',
          600: '#706C89',
          700: '#545167',
          800: '#383645',
          900: '#1C1B22',
        },
        primary: {
          100: '#EEEBFE',
          200: '#DDD7FE',
          600: '#4A2FF9',
          700: '#4E1AEA',
        },
        magnolia: {
          DEFAULT: '#F5F4FF',
          50: '#FAFAFF',
          100: '#FAFAFF',
          200: '#FAFAFF',
          300: '#FAFAFF',
          400: '#F6F5FF',
          500: '#F5F4FF',
          600: '#DEDBFF',
          700: '#C2BDFF',
          800: '#A299FF',
          900: '#695CFF',
        },
        purle: {
          DEFAULT: '#8370FF',
          50: '#F5F4FF',
          100: '#E0E4F6',
          200: '#CFD4EC',
          300: '#A092FF',
        },
        hi: {
          2: '#F5F5FA',
          3: '#D8D8EB',
          16: '#444447',
          20: '#1A1A1A',
          60: '#5C5CFF',
          100: '#0000FF',
          green: '#3EC728',
          khaki: '#9EC728',
          orange: '#FFC728',
          'green-new': '#36C26E',
        },
        // case studies
        'cs-magenta': '#DF02B1',
        'cs-purple': '#900FD3',
        'cs-coral-red': '#F16463',
        'cs-blue': '#3518E6',
        'cs-bright-pink': '#EA3B84',
      },
      animation: {
        pulsestarone: 'pulse .7s linear',
        pulsestartwo: 'pulse .5s .2s linear',
        pulsestarthree: 'pulse .5s .5s linear',
        loading: 'translatex 2s linear infinite',
      },
      keyframes: {
        pulse: {
          '0%, 100%': { opacity: 1, transform: 'scale(1)' },
          '50%': { opacity: 0.2, transform: 'scale(0.9)' },
        },
        translatex: {
          from: { backgroundPosition: '200% 0' },
          to: { backgroundPosition: '-200% 0' },
        },
      },
      fontFamily: {
        inter: ['Inter', ...defaultTheme.fontFamily.sans],
        serif: ['Roboto Serif', ...defaultTheme.fontFamily.serif],
        'dm-sans': ['DM Sans', ...defaultTheme.fontFamily.sans],
        'overpass-mono': ['Overpass Mono', ...defaultTheme.fontFamily.sans],
      },
      maxWidth: {
        1680: '1680px',
        1720: '1720px',
        1920: '1920px',
      },
      rotate: {
        15: '15deg',
        9: '9deg',
      },
    },
  },
  safelist: [
    {
      pattern:
        /^from-cs-(?:magenta|purple|coral-red|blue|bright-pink)(?:\/\d+)?$/,
    },
  ],
};
