import tailwindConfig from 'tailwind.config.js';

const breakpointsConfig = tailwindConfig.theme.screens;
const breakpoints = Object.entries(breakpointsConfig).reduce(
  (acc, [key, value]) => {
    acc[key] = parseInt(value);
    return acc;
  },
  {} as { sm: number; md: number; lg: number; xl: number; '2xl': number },
);

export default breakpoints;
