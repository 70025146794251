import React from 'react';

import Container from './container';

const LinkedinIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M17.7083 17.8397H14.042V12.0962C14.042 10.7265 14.0182 8.96392 12.134 8.96392C10.2499 8.96392 9.93298 10.4552 9.93298 11.9963V17.8397H6.26675V6.0292H9.78538V7.64416H9.83313C10.1848 7.04289 10.6949 6.54581 11.307 6.21153C11.9191 5.87508 12.6094 5.71228 13.3062 5.73833C17.0223 5.73833 17.7083 8.18248 17.7083 11.3625V17.8397Z"
      fill="#EDF0FF"
    />
    <path
      d="M2.12949 4.4164C1.70838 4.4164 1.29813 4.29268 0.946485 4.05825C0.59701 3.82382 0.323508 3.49171 0.16288 3.10316C8.07466e-05 2.71461 -0.0411616 2.28482 0.0413231 1.8724C0.123808 1.45998 0.325678 1.08011 0.623058 0.782733C0.920437 0.485353 1.3003 0.283483 1.71273 0.200998C2.12515 0.118513 2.55277 0.161926 2.94131 0.322555C3.32986 0.483183 3.66197 0.756685 3.8964 1.10616C4.13083 1.45563 4.25456 1.86806 4.25456 2.28917C4.25456 2.85353 4.03098 3.39403 3.63158 3.79343C3.23218 4.19283 2.69169 4.4164 2.12732 4.4164H2.12949Z"
      fill="#EDF0FF"
    />
    <path d="M3.96152 6.0292H0.290948V17.8375H3.96152V6.0292Z" fill="#EDF0FF" />
  </svg>
);

type Speaker = {
  companyImage: {
    asset: {
      url: any;
    };
  };
  companyName: string;
  fullName: string;
  id: string;
  link: string;
  role: string;
  order?: number;
  image: {
    asset: {
      gatsbyImageData: any;
    };
  };
};

const Speakers = ({ sessions }) => {
  const speakersMapObj = sessions.sessions.reduce((acc, session) => {
    const { speakers } = session;
    speakers.forEach((speaker) => {
      if (!acc[speaker.id]) {
        acc[speaker.id] = speaker;
      }
    });
    return acc;
  }, {});
  const speakers = (Object.values(speakersMapObj) as Speaker[])
    .sort((a, b) => a.companyName.localeCompare(b.companyName))
    ?.sort((a, b) => a.order - b.order);

  return (
    <div className="bg-[#F1F1F1]">
      <Container className="px-5 pb-24 pt-14 sm:px-10 md:pb-52 md:pt-44 lg:px-20">
        <h2 className="mb-11 text-left font-dm-sans text-[44px] font-bold leading-[48.4px] tracking-[-0.02em] text-[#1A1A1A] md:mb-28 md:text-center xl:text-[88px] xl:leading-[64px]">
          Speakers
        </h2>
        <div className="grid grid-cols-1 gap-x-6 gap-y-6 md:grid-cols-2 md:gap-y-16 lg:grid-cols-3 xl:grid-cols-4">
          {speakers.map((i) => {
            const { companyName, fullName, link, role, image, companyImage } =
              i;

            const profileSrc =
              image?.asset?.gatsbyImageData?.images?.fallback?.src;
            const companySrc = companyImage?.asset?.url;
            return (
              <a
                href={link}
                className="group relative flex flex-row items-center gap-6 rounded-full border border-[#EDF0FF] md:flex-col"
                key={fullName}
                target="_blank"
              >
                <div
                  className={`relative h-24 w-24 overflow-hidden rounded-full bg-cover bg-center md:h-60 md:w-60`}
                  style={{
                    backgroundImage: `url(${profileSrc})`,
                  }}
                >
                  <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center rounded-full opacity-0 group-hover:bg-[rgba(39,120,178,0.8)] group-hover:opacity-100">
                    <LinkedinIcon />
                  </div>
                </div>
                <div className="flex flex-col gap-1 text-left md:gap-3 md:text-center">
                  <p className="text-lg font-bold leading-[23.4px] text-[#070707] md:text-2xl md:leading-[29.05px]">
                    {fullName}
                  </p>
                  <p className="text-base font-normal leading-[25.6px] text-[#333333] md:text-center md:text-base md:leading-6">
                    {companySrc && (
                      <img
                        className="mb-2 h-7 max-w-36 md:mx-auto"
                        src={companySrc}
                        alt={companyName}
                      />
                    )}
                    {role}
                  </p>
                </div>
              </a>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export default Speakers;
