import React, { useState } from 'react';
import Container from '../container';
import SessionDetailsDrawer from './drawer';

type Session = {
  description: string;
  subTitle: string;
  from: string;
  to: string;
  title: string;
  videoId: string;
  speakers: {
    companyName: string;
    fullName: string;
    id: string;
    link: string;
    role: string;
    image: {
      asset: {
        gatsbyImageData: any;
      };
    };
  }[];
};

const Sessions = ({
  timezone,
  sessions,
}: {
  timezone: string;
  sessions: {
    title: string;
    oldTitle: string;
    sessions: Session[];
    oldSessions: Session[];
  };
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [session, setSession] = useState<Session>(sessions.sessions[0]);

  const handleClose = () => setIsOpen(false);

  const handleOpenSessionDetails = (newSession: Session) => {
    setIsOpen(true);
    setSession(newSession);
  };

  return (
    <div className="bg-[#1A1A1A]">
      <Container className="pb-20 pt-14 md:pb-0 md:pt-40">
        <div className="summit-speakers-heading">
          <h2 className="mb-7 font-dm-sans text-[44px] font-bold leading-[48.4px] text-white md:mb-9 md:text-center md:text-[88px] md:leading-[64px]">
            {sessions.title}
          </h2>
          <SessionDetailsDrawer
            timezone={timezone}
            isOpen={isOpen}
            handleClose={handleClose}
            session={session}
          />
          <ul className="flex flex-col">
            {sessions.sessions
              ?.slice()
              .sort((a, b) => {
                const fromDate = new Date(a.from);
                const toDate = new Date(b.from);
                return fromDate > toDate ? 1 : -1;
              })
              .map((i, index) => (
                <li
                  key={index}
                  className={`flex items-center gap-6 border-b border-solid border-[#A89AFF] py-8 text-[#A89AFF] first:border-t md:py-9`}
                >
                  <div className="mr-auto flex flex-col justify-between md:flex-row md:items-center md:lg:w-2/3">
                    <span className="text-lg font-bold leading-[23.4px] md:text-2xl md:leading-8">
                      {i.title}
                    </span>{' '}
                    <span className="text-lg font-normal leading-[27px] md:text-[22px] md:leading-9">
                      {i.subTitle}
                    </span>{' '}
                  </div>
                  <button
                    type="button"
                    onClick={() => handleOpenSessionDetails(i)}
                    className="group flex items-center gap-2 text-left text-xl font-normal leading-[38.89px] tracking-[0.5px] transition-colors hover:text-[#DBD6D0]"
                  >
                    <span className="hidden md:inline-block">Learn more</span>
                    <svg
                      width="24"
                      height="21"
                      viewBox="0 0 24 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 1.5C2.17031 1.5 1.5 2.17031 1.5 3V18C1.5 18.8297 2.17031 19.5 3 19.5H13.5V1.5H3ZM21 1.5H15V19.5H21C21.8297 19.5 22.5 18.8297 22.5 18V3C22.5 2.17031 21.8297 1.5 21 1.5ZM21 0C22.6547 0 24 1.34531 24 3V18C24 19.6547 22.6547 21 21 21H3C1.34531 21 0 19.6547 0 18V3C0 1.34531 1.34531 0 3 0H21Z"
                        className="fill-[#A89AFF] group-hover:fill-[#DBD6D0]"
                      />
                    </svg>
                  </button>
                </li>
              ))}
          </ul>
        </div>
      </Container>
    </div>
  );
};

export default Sessions;
