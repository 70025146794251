import { useKeenSlider } from 'keen-slider/react';
import React, { useEffect, useRef, useState } from 'react';
import { HiChevronRight, HiChevronLeft } from 'react-icons/hi';

type Session = {
  description: string;
  subTitle: string;
  time: string;
  title: string;
  videoId: string;
  speakers: {
    companyName: string;
    fullName: string;
    id: string;
    link: string;
    role: string;
    image: {
      asset: {
        gatsbyImageData: any;
      };
    };
  }[];
};

const Carousel = ({
  className,
  sessions,
}: {
  className?: string;
  sessions: Session[];
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderLoaded, setSetLoaded] = useState(false);
  const [sliderRef, sliderInstanceRef] = useKeenSlider<HTMLDivElement>({
    drag: true,
    mode: 'snap',

    loop: true,
    slides: { origin: 'auto', perView: 'auto', spacing: 20 },
    created(slider) {
      setSetLoaded(true);
    },
    updated(slider) {
      setSetLoaded(true);
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
  });

  // Add useEffect to re-render slider on window resize
  useEffect(() => {
    window.addEventListener('resize', () => {
      sliderInstanceRef.current?.update();
    });

    return () => {
      window.removeEventListener('resize', () => {
        sliderInstanceRef.current?.update();
      });
    };
  }, [sliderInstanceRef, sliderLoaded]);

  return (
    <div className={className}>
      <div className="keen-slider flex items-stretch pb-1" ref={sliderRef}>
        {sessions.map((i, index) => (
          <div
            key={index}
            className="keen-slider__slide flex w-full shrink-0 basis-[375px] flex-col items-center rounded-lg lg:basis-[500px] xl:basis-[753px]"
          >
            <iframe
              style={{
                maxWidth: '753px',
                width: '100%',
                display: 'block',
                height: '420px',
                margin: '0px',
              }}
              src={`https://play.vidyard.com/${i.videoId}`}
              data-uuid={i.videoId}
              data-v="4"
              data-type="inline"
            />
          </div>
        ))}
      </div>
      <div className="mt-16 flex items-center gap-2">
        <button
          type="button"
          className="flex h-9 w-9 items-center justify-center rounded-full bg-[#4A2FF9]"
          onClick={() => sliderInstanceRef.current?.prev()}
        >
          <HiChevronLeft size={22} color="white" />
        </button>
        <button
          type="button"
          className="flex h-9 w-9 items-center justify-center rounded-full bg-[#4A2FF9]"
          onClick={() => sliderInstanceRef.current?.next()}
        >
          <HiChevronRight size={22} color="white" />
        </button>
      </div>
    </div>
  );
};

export default Carousel;
