import React, { Suspense } from 'react';
import { useRef } from 'react';
import CtaButton from '../CtaButton';
import AnimatedLogos from '../Logos';
import { cn } from '@/utils/tailwindUtils';
import { extractDateAndTime, extractSessionTime } from '@/utils/date';

const GenAiHero = ({ hero }) => {
  const {
    title,
    version,
    eventDateTime,
    eventDateTimeEnd,
    ctaLink,
    timezone,
    subTitle,
    eventType,
    cost,
    logos,
  } = hero;

  const { formattedDate } = extractDateAndTime({
    eventDateTime,
    timezone,
  });

  const { formattedTime: startTime } = extractSessionTime({ eventDateTime });
  const { formattedTime: endTime } = extractSessionTime({
    eventDateTime: eventDateTimeEnd,
  });

  const data = [
    eventType,
    cost,
    formattedDate,
    `${startTime} - ${endTime} ${timezone}`,
  ];
  return (
    <div className="relative overflow-hidden bg-[#070707] px-5">
      <div className="mx-auto max-w-[1440px] bg-[url(/dots.png)] bg-cover bg-no-repeat">
        <div className="pb-[72px] pt-[72px] md:py-24 lg:pb-28 lg:pt-16">
          <h1 className="relative font-dm-sans text-4xl text-[44px] font-bold leading-[46.2px] tracking-[-0.02em] text-white md:text-[72px] md:leading-[80px] lg:flex lg:items-end xl:text-[130px] xl:leading-[138px]">
            <span className="inline-block max-w-min">{title}</span>
            <span className="relative ml-4 text-[#4A2FF9] md:ml-8">
              {version}
            </span>
          </h1>
          <ul className="my-6 flex max-w-fit items-center justify-start border-y border-solid border-y-[#FFFFFF33] py-2.5">
            {data.map((item, index) => (
              <li
                className={`border-solid border-[#FFFFFF33] text-left font-overpass-mono text-sm font-medium leading-[28.5px] text-white md:text-xl md:font-medium md:leading-[28.5px] ${index != 0 ? 'border-l-[1px] pl-4 md:pl-16' : ''} ${index != data.length - 1 ? 'border-r-[1px] pr-4 md:pr-16' : ''}`}
                key={item}
              >
                {item}
              </li>
            ))}
          </ul>
          <p className="mb-10 text-[22.5px] font-normal leading-[35px] text-[#FFFFFFCC]">
            {subTitle}
          </p>
          <CtaButton
            className="w-full text-center sm:w-fit sm:text-left"
            type="regular"
            link={ctaLink}
          />
        </div>
      </div>
      <AnimatedLogos logos={logos} dark borderless />
    </div>
  );
};

export default GenAiHero;
